function Feedback() {
  return (
      <div className="container">
        <h1>Зворотній звʼязок</h1>
        <div className="text-center">
          <p><a href="mailto: dokazovo@gmail.com" style={{ textDecoration: 'none' }}>dokazovo@gmail.com</a></p>
          <p><a href="https://t.me/get_random" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>@get_random</a></p>
        </div>
      </div>
  );
}

export default Feedback;